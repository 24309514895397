require('../css/video.scss');

var $ = require('jquery');

$(function() {
    if($("#page.video.list").length>0){

        $(document).delegate(".video-items p","click",function(){
            event.preventDefault();
             var img = $(this).attr('video-img');

            var url = $(this).attr('video-url');
            $(".video-info #video").attr('poster',img);
            $(".video-info #video").attr('src',url);
        });

    }



    if($("#page.video.show #id_zhibo_video").length>0){

        (function(){

             m3u8 = $("#id_zhibo_video").attr('m3u8');
             flv = $("#id_zhibo_video").attr('flv');
            var player =  new TcPlayer("id_zhibo_video", {

                "m3u8": m3u8,
                "flv": flv,
                "autoplay" : true,
            //    "coverpic" : "http://www.test.com/myimage.jpg",
                "width" :  "100%",//视频的显示宽度，请尽量使用视频分辨率宽度
                "height" : "400%"//视频的显示高度，请尽量使用视频分辨率高度
            });
        })()

    }


})

